import { getMesseDataAELF, getRefrainPsalmique } from "@/services/AELFService";
import ConfigItem, { ConfigItemImage } from "@/model/interfaces/ConfigItem";
import ConfigType from "@/model/enums/ConfigType";
import SupportMode from "@/model/enums/SupportMode";
import PsalmRefrainPositionId from "@/model/enums/PsalmRefrainPositionId";
import PPTColors from "@/model/interfaces/PPTColors";
import ConfigThematicType from "@/model/enums/ConfigThematicType";
import { groupBy } from "@/utils/RandomUtils";
import ConfigThematic, { configThematics } from "@/model/interfaces/ConfigThematic";
import NotificationStatus from "@/model/enums/NotificationStatus";
import StyleLocalStorage from "@/model/interfaces/StyleLocalStorage";
import StateType from "@/model/enums/StateType";
import PPTXGenJsLayout from "@/model/enums/PPTXGenJsLayout";
import FontFamily from "@/model/enums/FontFamily";

export const modulePowerPoint = {
  namespaced: true,
  state: () => ({
    messeDataAELF: null,
    refrainPsalmique: null as string | null,
    AELFActiveMesseKey: null,
    tempsLiturgique: null,
    config: {
      psalmRefrainPosition: PsalmRefrainPositionId.POSITION_1_1 as PsalmRefrainPositionId,
      date: null,
      style: "",
      ppt: [
        {
          id: "pptColors",
          data: {
            text: "#ffffff",
            background: "#000000",
          } as PPTColors,
          advanced: false,
          label: "Choisir les <strong>couleurs du PowerPoint</strong>",
          type: ConfigType.COLORS,
          thematic: ConfigThematicType.STYLE,
        },
        {
          id: "backgroundImage",
          data: null,
          advanced: false,
          label: "Ajouter une <strong>image de fond</strong>",
          type: ConfigType.IMAGE,
          thematic: ConfigThematicType.STYLE,
        },
        {
          id: "welcomeSlide",
          data: true,
          advanced: false,
          label: "Ajouter une <strong>page d'accueil</strong>",
          type: ConfigType.SWITCH,
          thematic: ConfigThematicType.FORMAT,
        },
        {
          id: "backgroundImageWelcomeSlide",
          data: null,
          advanced: true,
          label: "Ajouter une <strong>image à la page d'accueil</strong>",
          type: ConfigType.IMAGE,
          thematic: ConfigThematicType.STYLE,
        },
        {
          id: "backgroundImageBlankPage",
          data: null,
          advanced: true,
          label: "Ajouter une <strong>image sur les slides de transitions</strong>",
          type: ConfigType.IMAGE,
          thematic: ConfigThematicType.STYLE,
        },
        {
          id: "backgroundImageLectures",
          data: null,
          advanced: true,
          label: "Ajouter une <strong>image pour les slides des lectures</strong>",
          type: ConfigType.IMAGE,
          thematic: ConfigThematicType.STYLE,
        },
        {
          id: "blankPageBetween",
          data: true,
          advanced: true,
          label: "Ajouter une <strong>slide vide entre chaque chants</strong>",
          type: ConfigType.SWITCH,
          thematic: ConfigThematicType.FORMAT,
        },
        {
          id: "showBibleRefs",
          data: true,
          advanced: false,
          label: "Afficher les <strong>références bibliques du jour</strong>",
          type: ConfigType.SWITCH,
          thematic: ConfigThematicType.BIBLE,
        },
        {
          id: "showBibleTexts",
          data: false,
          advanced: false,
          label: "Afficher les <strong>textes bibliques du jour</strong>",
          type: ConfigType.SWITCH,
          thematic: ConfigThematicType.BIBLE,
        },
        {
          id: "showPsaumeStophes",
          data: false,
          advanced: true,
          label: "Afficher les <strong>strophes du Psaume</strong>",
          type: ConfigType.SWITCH,
          thematic: ConfigThematicType.BIBLE,
        },
        {
          id: "showNotrePere",
          data: false,
          advanced: false,
          label: "Afficher le <strong>Notre Père</strong>",
          type: ConfigType.SWITCH,
          thematic: ConfigThematicType.PRAYER,
        },
        {
          id: "showVerseNumber",
          data: false,
          advanced: false,
          label: "Afficher le <strong>numéro des couplets</strong>",
          type: ConfigType.SWITCH,
          thematic: ConfigThematicType.FORMAT,
        },
        {
          id: "showPriereSurLesOffrandes",
          data: false,
          advanced: true,
          label: "Afficher la <strong>Prière sur les offrandes</strong>",
          type: ConfigType.SWITCH,
          thematic: ConfigThematicType.PRAYER,
        },
        {
          id: "credo",
          data: null,
          advanced: false,
          placeholder: "Sélectionner un credo...",
          label: "Afficher le <strong>Credo</strong>",
          choices: [
            {
              name: "Ne pas afficher",
              value: null,
              content: null,
            },
            {
              name: "Symbole des apôtres",
              value: "Symbole des apôtres",
              content: `Je crois en Dieu, le Père tout-puissant,
Créateur du ciel et de la terre.
Et en Jésus Christ,
Son Fils unique, notre Seigneur ;
Qui a été conçu du Saint Esprit,
Est né de la Vierge Marie,
\\n
A souffert sous Ponce Pilate, a été crucifié,
Est mort et a été enseveli, est descendu aux enfers ;
Le troisième jour est ressuscité des morts,
Est monté aux cieux,
Est assis à la droite de Dieu le Père tout-puissant,
D’où il viendra juger les vivants et les morts.
\\n
Je crois en l’Esprit Saint,
à la sainte Église catholique,
à la communion des saints,
à la rémission des péchés,
à la résurrection de la chair, à la vie éternelle.
Amen`,
            },
            {
              name: "Symbole de Nicée Constantinople",
              value: "Symbole de Nicée Constantinople",
              content: `Je crois en un seul Dieu,
Le Père tout puissant,
Créateur du ciel et de la terre,
De l’univers visible et invisible,
\\n
Je crois en un seul Seigneur, Jésus Christ,
Le Fils unique de Dieu,
Né du Père avant tous les siècles :
Il est Dieu, né de Dieu,
Lumière, née de la lumière,
Vrai Dieu, né du vrai Dieu
\\n
Engendré non pas créé,
Consubstantiel au Père ;
Et par lui tout a été fait.
Pour nous les hommes, et pour notre salut,
Il descendit du ciel;
Par l’Esprit Saint,
Il a pris chair de la Vierge Marie,
Et s’est fait homme.
\\n
Crucifié pour nous sous Ponce Pilate,
Il souffrit sa passion et fut mis au tombeau.
Il ressuscita le troisième jour,
Conformément aux Ecritures, et il monta au ciel;
Il est assis à la droite du Père.
\\n
Il reviendra dans la gloire,
Pour juger les vivants et les morts
Et son règne n’aura pas de fin.
\\n
Je crois en l’Esprit Saint,
Qui est Seigneur et qui donne la vie;
Il procède du Père et du Fils.
Avec le Père et le Fils,
Il reçoit même adoration et même gloire;
Il a parlé par les prophètes.
\\n
Je crois en l’Eglise,
Une, sainte, catholique et apostolique.
Je reconnais un seul baptême
Pour le pardon des péchés.
J’attends la résurrection des morts,
Et la vie du monde à venir.
Amen`,
            },
          ],
          type: ConfigType.DROPDOWN,
          thematic: ConfigThematicType.PRAYER,
        },
        {
          id: "showSlideTitle",
          data: true,
          advanced: true,
          label: "Afficher les <strong>titres en haut des paroles</strong>",
          type: ConfigType.SWITCH,
          thematic: ConfigThematicType.FORMAT,
        },
        {
          id: "showShadowOnText",
          data: true,
          advanced: true,
          label: "Ajouter de l'<strong>ombre sur le texte</strong>",
          type: ConfigType.SWITCH,
          thematic: ConfigThematicType.STYLE,
        },
        {
          id: "fontFamily",
          data: "Calibri",
          advanced: true,
          placeholder: "Sélectionner une police d'écriture",
          label: "Choisir une <strong>police d'écriture</strong>",
          choices: [
            {
              name: "Calibri (défaut)",
              fontSize: 36,
              value: FontFamily.CALIBRI,
            },
            {
              name: "Aharoni",
              fontSize: 32,
              value: FontFamily.AHARONI,
            },
            {
              name: "Arial",
              fontSize: 34,
              value: FontFamily.ARIAL,
            },
            {
              name: "Trebuchet MS",
              fontSize: 32,
              value: FontFamily.TREBUCHET_MS,
            },
            {
              name: "Times New Roman",
              fontSize: 35,
              value: FontFamily.TIMES_NEW_ROMAN,
            },
          ],
          type: ConfigType.DROPDOWN,
          thematic: ConfigThematicType.STYLE,
        },
        {
          id: "layoutChoice",
          data: PPTXGenJsLayout.LAYOUT_16x9,
          advanced: true,
          placeholder: "Sélectionner un format...",
          label: "Choisir le <strong>format du PowerPoint</strong>",
          choices: [
            {
              name: "16:9 (défaut)",
              value: PPTXGenJsLayout.LAYOUT_16x9,
              h: 5.625,
              w: 10,
            },
            {
              name: "16:10",
              value: PPTXGenJsLayout.LAYOUT_16x10,
              h: 6.25,
              w: 10,
            },
            {
              name: "4:3",
              value: PPTXGenJsLayout.LAYOUT_4x3,
              h: 7.5,
              w: 10,
            },
          ],
          type: ConfigType.DROPDOWN,
          thematic: ConfigThematicType.STYLE,
        },  
        {
          id: "verticalAlignement",
          data: "middle",
          advanced: true,
          label: "Choisir l'<strong>alignement vertical des paroles</strong>",
          choices: [
            {
              label: "Centré",
              value: "middle",
            },
            {
              label: "Haut",
              value: "top",
            },
          ],
          type: ConfigType.RADIO,
          thematic: ConfigThematicType.STYLE,
        },
      ] as ConfigItem[],
      sheet: [  
        {
          id: "showBibleRefs",
          data: true,
          advanced: false,
          label: "Afficher les <strong>références bibliques du jour</strong>",
          type: ConfigType.SWITCH,
          thematic: ConfigThematicType.BIBLE,
        },
        {
          id: "showBibleTexts",
          data: false,
          advanced: false,
          label: "Afficher les <strong>textes bibliques du jour</strong>",
          type: ConfigType.SWITCH,
          thematic: ConfigThematicType.BIBLE,
        },
        {
          id: "parishName",
          data: "",
          placeholder: "Nom de votre paroisse ...",
          advanced: false,
          label: "Afficher le <strong>nom de votre paroisse</strong>",
          type: ConfigType.TEXT,
          thematic: ConfigThematicType.PARISH,
        },
        {
          id: "parishLogo",
          data: null,
          advanced: false,
          label: "Ajouter le <strong>logo de votre paroisse</strong>",
          type: ConfigType.IMAGE,
          showUnsplashImages: false,
          thematic: ConfigThematicType.PARISH,
        },
        {
          id: "showAnnonces",
          data: true,
          advanced: true,
          label: "Afficher des <strong>annonces</strong>",
          type: ConfigType.SWITCH,
          thematic: ConfigThematicType.PARISH,
        },
        {
          id: "stepTitleColor",
          data: "#000000",
          advanced: false,
          label: "Choisir la <strong>couleur des titres des étapes du déroulé</strong>",
          type: ConfigType.COLOR,
          thematic: ConfigThematicType.STYLE,
        },
        {
          id: "titleColor",
          data: "#000000",
          advanced: false,
          label: "Choisir la <strong>couleur du titre</strong>",
          type: ConfigType.COLOR,
          thematic: ConfigThematicType.STYLE,
        },
        {
          id: "showLiturgiqueDayName",
          data: true,
          advanced: true,
          label: "Afficher le <strong>nom du jour liturgique</strong>",
          type: ConfigType.SWITCH,
          thematic: ConfigThematicType.STYLE,
        },
        {
          id: "fontFamily",
          data: FontFamily.CAMBRIA,
          advanced: false,
          placeholder: "Sélectionner une police d'écriture",
          label: "Choisir une <strong>police d'écriture</strong>",
          choices: [
            {
              name: "Arial",
              fontSize: 19,
              spacingAfter: 100,
              value: FontFamily.ARIAL,
            },
            {
              name: "Calibri",
              fontSize: 19,
              spacingAfter: 100,
              value: FontFamily.CALIBRI,
            },
            {
              name: "Cambria (défaut)",
              fontSize: 19,
              spacingAfter: 100,
              value: FontFamily.CAMBRIA,
            },
            {
              name: "Garamond",
              fontSize: 19,
              spacingAfter: 120,
              value: FontFamily.GARAMOND,
            },
            {
              name: "Georgia",
              fontSize: 19,
              spacingAfter: 120,
              value: FontFamily.GEORGIA,
            },
            {
              name: "Times New Roman",
              fontSize: 19,
              spacingAfter: 120,
              value: FontFamily.TIMES_NEW_ROMAN,
            },
            {
              name: "Trebuchet MS",
              fontSize: 19,
              spacingAfter: 100,
              value: FontFamily.TREBUCHET_MS,
            },
          ],
          type: ConfigType.DROPDOWN,
          thematic: ConfigThematicType.STYLE,
        },
      ]
    },
  }),
  mutations: {
    initialiseStore(state) {
      // (cf article https://www.mikestreety.co.uk/blog/vue-js-using-localstorage-with-the-vuex-store/)
      // Check if the ID exists
      if (localStorage.getItem("configPpt")) {
        // Replace the state object with the stored item
        const savedConfigs = JSON.parse(localStorage.getItem("configPpt"));

        if (savedConfigs.date !== undefined) {
          this.state.app.savedSessionDate = new Date(
            savedConfigs.date
          ).toLocaleTimeString("fr-FR", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          });
        }

        if (savedConfigs.mode !== undefined) {
          this.state.app.mode = savedConfigs.mode;
        }

        if (savedConfigs.data !== undefined) {
          savedConfigs.data.forEach((savedConfig: ConfigItem) => {
            state.config.ppt.every((config: ConfigItem, key: number) => {
              if (config.id === savedConfig.id && savedConfig.data !== undefined) {
                state.config.ppt[key].data = savedConfig.data;
                return false;
              }
              return true;
            });
          });
        }
      }
    },
    setState: function (state, payload) {
      const isStyleState = payload.stateType == StateType.STYLE
      Object.keys(state).forEach((key) => {
        switch (key) {
          case 'messeDataAELF':
          case 'refrainPsalmique':
          case 'tempsLiturgique':
          case 'AELFActiveMesseKey':
            if (!isStyleState) {
              state[key] = payload.state[key] ?? state[key];
            }
            break;
          case 'config':
            Object.keys(state[key]).forEach((i) => {
              if (i === 'style' || (i === 'date' && isStyleState)) {
                console.log("Do not set style")
              } else if (!Array.isArray(state[key][i]) && typeof state[key][i] !== 'object' || state[key][i] == null) {
                state[key][i] = payload.state[key][i] ?? state[key][i];
              } else if (Array.isArray(state[key][i])) {
                state[key][i].forEach((el: any, j: number) => {
                  if (el != null && 'id' in el) {
                    payload.state[key][i].forEach((savedEl: any) => {
                      if (el.id === savedEl.id) {
                        // Handle legacy settings data structure
                        if (typeof savedEl.data === 'object' && savedEl.choices !== undefined) {
                          state[key][i][j].data = savedEl.data?.value ?? savedEl.data?.name
                        } else {
                          state[key][i][j].data = savedEl.data
                        }
                      }
                    })
                  }
                });
              }
            })
            break;
          default:
            break;
        }
      });
    },
    setMesseDataAELF: function (state, messeDataAELF) {
      state.messeDataAELF = messeDataAELF
      state.AELFActiveMesseKey = null;

      // Si plusieurs messes, pour une solennité par exemple
      // demander à l'utilisateur de sélectionner celle qu'il prépare
      if (state.messeDataAELF.messes.length > 1) {
        this.commit("app/setAELFMessePickerShown", true)
      } else {
        state.AELFActiveMesseKey = 0;
        this.dispatch("ppt/updateAELFValues");
      }
    },
    setConfig: function (state, configItem: ConfigItem) {
      const mode = this.state.app.mode
      state.config[mode].forEach((config: ConfigItem, key: number) => {
        if (config.id === configItem.id) {
          state.config[mode][key].data = configItem.data;
        }
      });
      this.dispatch("ppt/saveConfigInLocalStorage");
    },
    async setDate(state, date) {
      state.config.date = date;
      state.messeDataAELF = null;
      if (date === null) {
        state.tempsLiturgique = null;
      } else {
        await this.dispatch("ppt/fetchAELFData");        
      }
    },
    updateRefrainPsalmique: function (state, refrainPsalmique: string) {
      state.refrainPsalmique = refrainPsalmique
    },
    updateActiveRefrainPsalmiquePosition: function (state, positionId: PsalmRefrainPositionId) {
      state.config.psalmRefrainPosition = positionId
    },
    setAELFActiveMesseKey: function (state, AELFActiveMesseKey: number) {
      state.AELFActiveMesseKey = AELFActiveMesseKey
      if (AELFActiveMesseKey !== null) {
        this.commit("app/setAELFMessePickerShown", false)
      }
    },
    setStyle: function (state, style: StyleLocalStorage) {
      state.config.style = style.name;
      this.dispatch("ppt/saveStyleInLocalStorage", style);
    }
  },
  actions: {
    saveStyleInLocalStorage({ commit, state, rootState }, payload: StyleLocalStorage) {
      const storedStyles = localStorage.getItem('styles')
      let styles: any[] = [];
      let styleExists = false;

      if (storedStyles != null) {
        styles = JSON.parse(storedStyles);
        styles.forEach((style: StyleLocalStorage, key: number) => {
          if (style.name == state.config.style) {
            styles[key].usedAt = new Date().getTime()
            styleExists = true
          }
        });
      }
      
      if (!styleExists) {
        const style: StyleLocalStorage = {
          name: state.config.style,
          usedAt: new Date().getTime(),
          isOwner: payload.isOwner ?? false
        };
        styles.push(style)
      }

      this.commit('app/setUsedStyles', styles)
      styles = styles.filter((style: StyleLocalStorage) => style.name != "")
      localStorage.setItem('styles', JSON.stringify(styles));
    },
    saveConfigInLocalStorage({ commit, state, rootState }) {
      try {
        localStorage.setItem(
          "configPpt",
          JSON.stringify({
            data: state.config.ppt,
            date: new Date().getTime(),
            mode: rootState.app.mode,
          })
        );
      } catch (err: any) {
        if (err.name === 'QuotaExceededError') {
          
          this.commit('app/addNotification', {
            message: "L'enregistrement de vos paramètres de session sur votre navigateur n'a pas marché",
            description: "Les images ajoutées sont trop lourdes, nous vous conseillons d'utiliser un style",
            status: NotificationStatus.INFO,
          })
        }
      }
    },
    async fetchAELFData({ commit, state, rootState }) {
      const data = await getMesseDataAELF()
      commit("setMesseDataAELF", data)
    },
    updateAELFValues({ commit, state, rootState }) {
      state.refrainPsalmique = getRefrainPsalmique()
      state.tempsLiturgique = state.messeDataAELF.informations.temps_liturgique;
    },
  },
  getters: {
    getConfigs: (state, getters, rootState): ConfigItem[] => {
      if (rootState.app.mode === SupportMode.PPT) return state.config.ppt
      else if (rootState.app.mode === SupportMode.SHEET) return state.config.sheet
      else return []
    },
    getConfigsPerThematic: (state, getters, rootState): ConfigThematic[] => {
      const configsGroupedPerThematics: { [key in ConfigThematicType] : ConfigItem[]; } = groupBy(getters.getConfigs, "thematic")
      const configThematicsArray: ConfigThematic[] = [];
      Object.keys(configsGroupedPerThematics).forEach((thematic: ConfigThematicType) => {
        const configThematic = configThematics.find((configThematic: ConfigThematic) => configThematic.type === thematic)
        if (configThematic) {
          configThematic['configs'] = configsGroupedPerThematics[thematic]
          configThematicsArray.push(configThematic)
        }
      });
      return configThematicsArray.sort((a,b) => a.priority - b.priority);
    },
    getConfig: (state, getters, rootState) => (configId: ConfigItem['id']): ConfigItem => {
      const activeMode = rootState.app.mode ?? SupportMode.PPT
      const originalSupportModes = Object.values(SupportMode)
      const supportModes = [
          ...originalSupportModes.filter((mode: SupportMode) => mode == activeMode),
          ...originalSupportModes.filter((mode: SupportMode) => mode != activeMode),
      ];

      let configItem: ConfigItem | null = null;

      supportModes.some((mode) => {
        configItem = state.config[mode].find((config: ConfigItem) => configId === config.id)
        if (configItem != undefined) {
          return true;
        }
      })

      return configItem ?? null
    },
    getConfigItemChoice: (state, getters) => (configId: ConfigItemDropdown['id']): ConfigItemChoice | null => {
      const config = getters.getConfig(configId);
      return config.choices.find((choice: ConfigItemChoice) => {
        return choice.value == config.data
      }) ?? null
    },
    getDate: (state) => {
      const date = state.config.date;
      if (date === null) return null;
      const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }
      return new Date(date).toLocaleDateString("fr-FR", options);
    },
    getActiveAELFMesse (state) {
      return state.messeDataAELF?.messes[state.AELFActiveMesseKey ?? 0]
    },

    getUploadedConfigImg (state): ConfigItemImage[] {
      const configImg =  state.config.ppt
        .filter((config: ConfigItem) => config.type === ConfigType.IMAGE)
        .filter((config: ConfigItemImage) => config.data !== null && config.data.uploadedImageData !== null)
      return configImg
        
    },
    getUploadedConfigImgCount (state, getters): number {
      return getters.getUploadedConfigImg.length
    }
  },
}
