
import LectureReference from "@/model/enums/LectureReference";
import StepDeroule from "@/model/interfaces/StepDeroule";
import { removeParenthesesContent, removeBracketsContent, removeSubstrings } from '@/utils/RandomUtils'
import store from "@/store"

const AELF_API_ENDPOINT =  "https://api.aelf.org/v1/messes";

async function parseFile(url: string) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(response => response.json())
      .then(data => resolve(data))
      .catch(err => { reject(err) })
  });
}

export function getBibleRef(stepRef: StepDeroule['refId'], showVerse = true): string | null {
  const lecture = getLecture(stepRef)

  if (lecture != null) {
    if (showVerse) {
      let verse = lecture.titre;
      if (lecture.verset_evangile != null) {
        verse = lecture.verset_evangile.replace(/<strong>(.*)<\/strong>/g, "").replace(/<\/?[^>]+(>|$)/g, "");
      }
      return lecture.ref + "\n" + verse
    } else {
      return "(" + lecture.ref + ")";
    }
  }
  return null;
}

export function getLecture(stepRef: StepDeroule['refId']) {
  const isStepPsaume = isPsaume(stepRef)
  return store.getters['ppt/getActiveAELFMesse'].lectures.find((l: any) => (l.type === stepRef) || (isStepPsaume && isPsaume(l.type)))
}

export function getBibleText(stepRef: StepDeroule['refId'], showVerse = true): string | null {
  const lecture = getLecture(stepRef)

  if (lecture != null) {
    let contenu = lecture.contenu
    const textsToRemove = [
      '– Acclamons la Parole de Dieu.',
      '– Parole du Seigneur.',
      'OU BIEN',
      'OU LECTURE BRÈVE',
    ];
    contenu = removeSubstrings(contenu, textsToRemove)

    if (isPsaume(stepRef) && store.state.ppt.refrainPsalmique !== null) {
      contenu = contenu.replace(/(\r\n|\n|\r)/gm, "");
      contenu = `<strong>${store.state.ppt.refrainPsalmique}</strong>` + contenu
    } else {
      contenu = cleanHtmlTags(contenu)
    }
    
    return contenu.trim();
  }
  return null;
}

export function isPsaume(lectureType): boolean {
  return lectureType === LectureReference.PSAUME || lectureType === LectureReference.CANTIQUE
}

export function getRefrainPsalmique(): string | null {
  const psaume = store.getters['ppt/getActiveAELFMesse'].lectures.find((l: any) => isPsaume(l.type))
  if (psaume) {
    let contenu = psaume.refrain_psalmique
    contenu = cleanHtmlTags(contenu)
    const textsToRemove = [
      'ou : Alléluia !',
      'ou : Alléluia.',
      'ou Alléluia !',
    ];
    return removeSubstrings(contenu, textsToRemove);
  }
  return null;
}

export function getJourLiturgique(): string | null {
  const jourLiturgique = store.state.ppt.messeDataAELF?.informations?.jour_liturgique_nom
  if ("de la férie" === jourLiturgique) {
    return null
  }
  return jourLiturgique ? removeBracketsContent(removeParenthesesContent(jourLiturgique)).trim() : null
}

export function cleanHtmlTags(html: string): string {
  const div = document.createElement("div"); 
  div.innerHTML = html; 
  const text = div.textContent || div.innerText || ""; 
  return text.replace(/\s+/g, ' ').trim();
}

export async function getMesseDataAELF() {
  return await parseFile(AELF_API_ENDPOINT + `/${store.state.ppt.config.date}/france`);
}

export function getMesseLinkAELF(): string {
  return `https://www.aelf.org/${store.state.ppt.config.date}/romain/messe`;
}