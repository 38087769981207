export enum FontFamily {
  CALIBRI = 'Calibri',
  CAMBRIA = 'Cambria',
  AHARONI = 'Aharoni',
  ARIAL = 'Arial',
  GARAMOND = 'Garamond',
  GEORGIA = 'Georgia',
  TIMES_NEW_ROMAN = 'Times New Roman',
  TREBUCHET_MS = 'Trebuchet MS',
}

export default FontFamily;