import { createRouter, createWebHistory } from 'vue-router'
import { readBin, denormalizeState } from '@/services/JSONBinService'
import store from '@/store'
import StyleService from '@/services/State/StyleService'
import DerouleService from '@/services/State/DerouleService'
import StateType from '@/model/enums/StateType'

const Home = () =>            import('@/views/Home.vue')
const Edit = () =>            import("@/views/Edit/Edit.vue")
const FAQ = () =>             import("@/views/FAQ.vue")
const MentionsLegales = () => import("@/views/MentionsLegales.vue")
const ChangeLog = () =>       import("@/views/ChangeLog.vue")
const Contact = () =>         import("@/views/Contact.vue")
const Init = () =>            import("@/views/Init/Init.vue")
const Init1Mode = () =>       import("@/views/Init/Init1Mode.vue")
const Init2Date = () =>       import("@/views/Init/Init2Date.vue")
const Init3Ordinaire = () =>  import("@/views/Init/Init3Ordinaire.vue")
const Init4Settings = () =>   import("@/views/Init/Init4Settings.vue")
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/init',
    // name: 'init',
    component: Init,
    // redirect: to => {
    //   return {name: "init1"}
    // },
    children: [
      {
        path: "",
        name: "init",
        redirect: { name: "init1" }
      },
      {
        path: '1',
        name: 'init1',
        component: Init1Mode,
      },
      {
        path: '2',
        name: 'init2',
        component: Init2Date,
      },
      {
        path: '3',
        name: 'init3',
        component: Init3Ordinaire,
      },
      {
        path: '4',
        name: 'init4',
        component: Init4Settings,
      },
    ],
  },
  {
    path: '/app',
    name: 'Edit',
    component: Edit,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/mentions-legales',
    name: 'Mentions légales',
    component: MentionsLegales
  },
  {
    path: '/quoi-de-neuf',
    name: 'Quoi de neuf ?',
    component: ChangeLog
  },
  {
    path: '/who-am-i',
    name: 'Contact',
    component: Contact
  },
  // Leagacy path when using JSONBin.io 
  {
    path: '/app/:id',
    name: 'share',
    component: Edit,
    async beforeEnter(to: any, from: any, next: any) {
      const state = await readBin(to.params.id).catch(
        err => store.dispatch('app/addErrorNotification', err
      ));
      if (state === null) {
        next({ name: 'Edit' })
      }
      denormalizeState(state, StateType.DEROULE)
      next()
    },
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path != from.path) {
    if (to?.query != null && to.query.length) {
      const toWithoutQuery = JSON.parse(JSON.stringify(to));
      let changed = false
      to.query.forEach((queryElem : string) => {
        if (toWithoutQuery.query[queryElem] == '') {
          changed = true;
          toWithoutQuery.query[queryElem] = undefined;
        }
      });
      changed ? next(toWithoutQuery) : next();
    } else if (from.query.length) {
      const toWithQuery = Object.assign({}, to, {query: from.query});
      next(toWithQuery)
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach(async (to, from, next) => {

  // Load style
  if (to.query.style && typeof to.query.style === 'string') {
    if (store.state.ppt.config.style !== to.query.style) {
      const isStyleLoaded = await StyleService.loadStyle(to.query.style)
      store.commit('ppt/setStyle', { name: (isStyleLoaded ? to.query.style : '') })
    } 
  }
  
  // Load deroule
  if (to.query.deroule && typeof to.query.deroule === 'string') {
    if (store.state.app.derouleKey !== to.query.deroule) {
      const isDerouleLoaded = await DerouleService.loadDeroule(to.query.deroule)
      store.commit('app/setDerouleKey', isDerouleLoaded ? to.query.deroule : '')
    }
  }
})
export default router

