import NotificationStatus from "@/model/enums/NotificationStatus";
import { denormalizeState } from "@/services/JSONBinService";
import store from "@/store";
import DerouleGitlabService from "@/services/State/DerouleGitlabService";
import StateType from "@/model/enums/StateType";
import DerouleLocalStorage from "@/model/interfaces/DerouleLocalStorage";

export default class DerouleService {

  static async loadDeroule(derouleKey: string): Promise<boolean> {
    if (derouleKey !== '') {
      const notificationId = Date.now();
      store.commit('app/addNotification', {
        id: notificationId,
        message: `🪂 Récupération du déroulé...`,
        description: `Cela peut prendre plusieurs secondes.`,
        status: NotificationStatus.LOADING,
      })
      return await DerouleGitlabService.getStoredState(derouleKey)
      .then(state => {
        setTimeout(() => {
          denormalizeState(state, StateType.DEROULE)
          store.commit('app/updateNotification', {
            id: notificationId,
            message: `Le déroulé <code>${derouleKey}</code> a été appliqué !`,
            description: null,
            status: NotificationStatus.SUCCESS,
          })
        }, 1500)
        return true
      })
      .catch(
        (err: Error) => {
          if (err.cause && err.cause.status === 404) {
            store.commit('app/updateNotification', {
              id: notificationId,
              message: err.message,
              description: "Vérifiez que le lien soit bien le bon.",
              status: NotificationStatus.ERROR,
            })
          } else {
            store.dispatch('app/addErrorNotification', err.message) 
          }
          return false;
        }
      );
    } else {
      return false;
    }
  }

  static parseDateFromDerouleKey(derouleKey: DerouleLocalStorage['key']) {
    
    const year = parseInt(derouleKey.substring(0, 2));
    const month = parseInt(derouleKey.substring(2, 4)) - 1; // Month is zero-based in Date object
    const day = parseInt(derouleKey.substring(4, 6));
    const hour = parseInt(derouleKey.substring(6, 8));
    const minute = parseInt(derouleKey.substring(8, 10));
    const second = parseInt(derouleKey.substring(10, 12));

    const date = new Date(2000 + year, month, day, hour, minute, second);
    return date;
  }

}