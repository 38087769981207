import StateType from "@/model/enums/StateType";
import AbstractStateGitlabService from "./AbtsractStateGitlabService";
import store from "@/store";

export default class StyleGitlabService {

  static async createStyleGitlabMR(styleSlug: string, update = false) {
    await AbstractStateGitlabService.createStateGitlabMR(styleSlug, update, StateType.STYLE)
  }

  static async getStoredState(branchName: string) {
    return AbstractStateGitlabService.getStoredState(branchName, StateType.STYLE)
  }
}