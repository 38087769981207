import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VCalendar from 'v-calendar';
import * as Sentry from "@sentry/vue";
import { readSongsData, readOrdinairesData } from "@/services/FileService";
import Song from './model/interfaces/Song';
import Ordinaire from './model/interfaces/Ordinaire';
import { registerSW } from 'virtual:pwa-register'
import DOMPurify from "dompurify";

registerSW({ immediate: true })

const images = import.meta.globEager('@/assets/**/*.(png|svg|gif|jpe?g)', { as: 'url' });
const mixin = {
  methods: {
    $require(name: string) {
      name = name.replace("@/", "/src/")
      return images[Object.keys(images).find((path) => path === name)];
    }
  }
}

readSongsData().then((songs: Partial<Song>[]) => {
  store.commit("song/setSongs", songs);
});

readOrdinairesData().then((ordinaires: Ordinaire[]) => {
  store.commit("song/setOrdinaires", ordinaires);
});

function isStyleParamSet(): boolean {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get('style') != null;
}

const app = createApp({
  extends: App,
  mounted() {
    store.commit('app/initialiseStore');
    if (!store.state.ppt.style && !isStyleParamSet()) {
      store.commit('ppt/initialiseStore');
    }
  }
})
Sentry.init({
  app,
  enabled: process.env.NODE_ENV !== 'development',
  dsn: "https://3812bfa5e26463de9e6ffb424729f556@o4506111223791616.ingest.sentry.io/4506111226085376",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/messetoolkit\.fr/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const clickOutside = {
  beforeMount: (el, binding) => {
    if (el != null) {
      el.clickOutsideEvent = event => {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          binding.value();
        }
      };
      document.addEventListener("click", el.clickOutsideEvent);
    }
  },
  unmounted: el => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

app
  .use(store)
  .use(router)
  .mixin(mixin)
  .directive("click-outside", clickOutside)
  .use(vuetify)
  .use(VCalendar, {})
  .directive('san-html', (el, binding) => {
    if (el != null) {
      el.innerHTML = DOMPurify.sanitize(binding.value);
    }
  })
  .mount('#app')
