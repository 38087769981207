/* eslint-disable no-console */
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for(let registration of registrations) {
      console.log(registration)
      if (registration.active?.scriptURL === `${import.meta.env.BASE_URL}service-worker.js`) {
        registration.unregister();
        console.log("Successfully unregistered old ", `${import.meta.env.BASE_URL}service-worker.js`)
      }
    } 
  });
}