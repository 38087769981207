import StateType from "@/model/enums/StateType";
import AbstractStateGitlabService from "./AbtsractStateGitlabService";
import store from "@/store"

export default class DerouleGitlabService {

  static async createDerouleGitlabMR(derouleKey: string, update = false) {
    await AbstractStateGitlabService.createStateGitlabMR(derouleKey, update, StateType.DEROULE)
      .then(() => {
        store.commit('app/setDerouleKey', derouleKey)
      })
  }

  static async getStoredState(branchName: string) {
    return AbstractStateGitlabService.getStoredState(branchName, StateType.DEROULE)
  }
}