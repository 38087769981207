<template>
  <div class="NotificationBox">
    <Notification v-for="notification in notifications" :key="notification.id" :notification="notification" />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'
import Notification from '@/components/Notification.vue'
import type NotificationType from '@/model/interfaces/Notification';

@Component({
  components: { Notification }
})
export default class NotificationBox extends Vue {

  get notifications(): NotificationType[] {
    return this.$store.state.app.notifications
  }
}
</script>

<style lang="less">
.NotificationBox {
  display: flex;
  flex-flow: column-reverse nowrap;
  align-items: flex-end;
  gap: .75rem;
  pointer-events: none;
}
</style>