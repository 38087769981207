<template>
  <router-view>
  </router-view>
  <NotificationBox />
  <PreloadImages />
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';
import NotificationBox from "@/components/NotificationBox.vue";
import PreloadImages from '@/components/PreloadImages.vue';

@Component({
  components: {
    NotificationBox,
    PreloadImages
  }
})
export default class App extends Vue {}
</script>
<style lang="less">
html, body, #app {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
  scroll-behavior: smooth;
}

.red {
  color: @color-ppt;
}
#app {
  * {
    font-family: @font-secondary;
  }

  h1, h2, h3, h4 {
    &, & * {
      font-family: @font-primary !important;
    }
  }
  .show-sm {
    display: none;
  }

  .NotificationBox {
    position: fixed;
    bottom: 0;
    right: 0;
    @dim-margin-x: 1.5rem;
    max-width: calc(100% - @dim-margin-x * 2);
    margin: @dim-margin-x;
    width: fit-content;
    z-index: @z-notifications;
  }
}
.section {
  width: 900px;
  height: 100%;
  max-width: calc(100% - 6rem);
  margin: auto;
}
button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 0.9em;
  &, & * {
    font-family: @font-primary !important;
  }
}
.Btn {
  text-decoration: none;
  border: solid 1px @color-primary;
  color: @color-primary;
  border-radius: @dim-radius;
  height: 2.75rem;
  padding: 0 1.5rem;
  background: none;
  background: white;
  font-weight: bold;
  transition: all .15s ease-in;
  font-weight: 400;
  white-space: pre;

  * {
    transition: all .15s ease-in;
  }

  .ButtonLoader {
    max-height: 65%;
    margin-right: .5rem;
  }

  &, > * {
    font-size: 0.9rem;
    color: @color-primary;
    white-space: pre;
  }

  &[disabled] {
    pointer-events: none !important;
    filter: brightness(1.3) grayscale(0.4) saturate(0.8);
  }

  &--tertiary {
    filter: grayscale(1);
    &, > * {
      color: #777;
    }
  }

  b {
    text-transform: uppercase;
  }

  img {
    margin-left: 1rem;
    max-height: 40%;
    transition: all .1s ease-in;
  }

  &:hover, &--primary {
    background: @color-primary;

    &, > * {
      font-size: 0.9rem;
      color: white;
    }

    img {
      filter: brightness(100);
    }
  }

  &.red {
    border-color: @color-ppt;
    &:hover {
      background: @color-ppt !important;
    }
  }
}
a {
  text-decoration: underline;
  color: black;
  position: relative;
  z-index: 1;
  font-weight: 600;
}
mark {
  background: none;
  position: relative;
  font-weight: bold;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    background: @color-primary--fade-1;
    width: calc(100% - 10px);
    height: 55%;
    top: 0;
    left: 0;
    z-index: -1;
    transform: translate(10px, 75%);
  }
}
.Btn__action {
  font-size: 0.8em;
  color: white;
  background: @color-primary;
  @dim-btn-action: 2.8rem;
  width: @dim-btn-action;
  height: @dim-btn-action;
  font-weight: 400;
  border-radius: 50%;
  // border-radius: 3px;
  display: flex;
  align-items: center;
  position: relative;
  padding: initial;
  box-shadow: 0 4px 14px -3px fade(@color-primary, 30%);
  transition: all .15s ease-in;
  border: solid darken(@color-primary, 10%);
  border-width: 0;

  &.red {
    background: @color-ppt;
    border-color: darken(@color-ppt, 10%);

    &::after {
      background: fade(@color-ppt, 20%);
      color: @color-ppt;
    }
  }

  &.grey {
    background: @color-grey;
    border-color: darken(@color-grey, 10%);

    &::after {
      background: fade(@color-grey, 20%);
      color: @color-grey;
    }
  }

  &:hover {
    transform: scale(1.1);
    border-width: 3px;

    &::after {
      opacity: 1;
    }
  }

  &[data-tooltip]::after {
    content: attr(data-tooltip);
    transition: all .15s ease-in;
    opacity: 0.8;
    position: absolute;
    right: 100%;
    text-align: right;
    color: white;
    color: @color-primary;
    margin-right: 1rem;
    // min-width: 13rem;
    font-weight: bold;
    background: fade(@color-primary, 20%);
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline;
    font-size: 1em;
    line-height: 1.1em;
    white-space: nowrap;
  }

  img {
    max-height: 45%;
    margin: 0;
  }
}
@media (max-width: @bp-sm) {

  .Btn {
    width: 100% !important;
    font-size: 1em;
  }

  .Btn__action {
    font-size: 0.9em;
  }
  #app {

    .hide-sm {
      display: none;
    }
    .show-sm {
      display: inherit;
    }

    .NotificationBox {
      top: 0;
      bottom: inherit;
    }
  }
  .section {
    max-width: calc(100% - 3rem);
  }
}

.Song__selectCtnBg {
  position: fixed;
  inset: 0;
  background: fade(@color-primary, 50%);
  z-index: 1;
  opacity: 0;
  transition: opacity .2s ease-in;
  pointer-events: none;

  &[show="true"] {
    opacity: 1;
    pointer-events: inherit;
    cursor: pointer;
  }
}

[new] {
  position: relative;
  &::before {
    content: "NEW";
    padding: 0.05rem .25rem;
    background: #575757;
    color: white;
    position: absolute;
    font-size: 0.5em;
    top: 100%;
    right: 0.5rem;
    transform: translateY(-50%);
    border-radius: 2px;
  }
}

[beta='true'], [new='true'] {
  position: relative;
  &::before {
    content: "---";
    padding: 0.05rem .25rem;
    background: @color-primary;
    color: white;
    position: absolute;
    font-size: 0.5em;
    top: 100%;
    right: 0.5rem;
    transform: translateY(-50%);
    border-radius: 2px;
    z-index: 1;
  }
}

[beta='true']::before {
  content: "EXPÉRIMENTAL";
}

[new='true']::before {
  content: "NOUVEAU";
}

[highlight='true'] {
  position: relative;
  &::before {
    content: "";
    background: #e43f3f;
    position: absolute;
    font-size: 0.5em;
    height: 0.4rem;
    width: 0.4rem;
    top: 90%;
    right: 0.25rem;
    transform: translateY(-50%);
    border-radius: 5px;
  }
}

.Modal {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  inset: 0;
  height: 100%;
  max-height: 100%;
  pointer-events: none;
  overscroll-behavior: contain;
  background: fade(@color-primary, 75%);
  z-index: @z-ppt-generation;
  background: radial-gradient(circle, fade(@color-primary, 75%) 20%, rgba(255,255,255,0) 100%);
  transition: all .15s ease-in;
  opacity: 0;

  &[shown="false"] * {
    pointer-events: none;
  }

  &[shown="true"] {
    opacity: 1;
    pointer-events: inherit;
  }

  .Modal__ctn {
    max-width: 100%;
    position: relative;
    width: 40rem;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: @dim-radius;
    background: white;
    box-shadow: 0 1rem 2rem -1rem @color-primary--fade-1;
    overflow-y: auto;
    max-height: 100%;

    .Modal__close {
      .mixin-close;
    }
  }
}

.ShareLinkCtn {
  display: flex;
  flex-flow: row nowrap;
  &[saved] .Btn--highlight {
    border-radius: @dim-radius 0 0 @dim-radius;
  }
  .InputCopyClipboard {
    border-width: 0 0 0 1px !important;
    border-radius: 0 @dim-radius @dim-radius 0;
    max-width: 7.5rem;
    height: inherit;

    span {
      direction: rtl;
    }
  } 
}

@media (max-width: @bp-sm) {
  [tooltip]::after {
    content: none !important;
  }
}

[tooltip] {
  position: relative;

  &[tooltip-type="error"]::after {
    background: @color-error;
  }

  &[tooltip-x="right"]::after {
    left: 100%;
    transform-origin: left !important;
    margin-left: 0.5rem;

    &[tooltip-y="top"]::after {
      transform-origin: left top;
    }
    &[tooltip-y="bottom"]::after {
      transform-origin: left bottom;
    }
  }

  &[tooltip-x="left"]::after {
    right: 100%;
    left: inherit;
    transform-origin: right center;
    top: inherit;
    bottom: inherit;
    margin-right: 0.5rem;
  }

  &[tooltip-y="top"]::after {
    bottom: inherit;
  }

  &[tooltip-y="bottom"]::after {
    top: inherit;
  }

  &::after {
    content: attr(tooltip);
    height: fit-content;
    width: max-content;
    text-transform: initial !important;
    position: absolute;
    transform: scale(0.6);
    opacity: 0;
    transform-origin: left center;
    pointer-events: none;
    padding: 0.375rem 0.75rem;
    border-radius: 5px;
    background: @color-primary;
    color: white;
    max-width: 19rem;
    display: inline;
    line-height: 1.4em;
    z-index: @z-tooltip;
    font-size: 0.8rem;
    font-weight: 500;
    left: 100%;
    margin: auto .5rem;
    top: 0;
    bottom: 0;
    transition: all 0.15s ease-in;
  }

  &:hover {
    &::after {
      opacity: 1;
      transform: scale(1);
    }
  }
}
</style>
