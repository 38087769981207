import { createStore } from 'vuex'
import { moduleApp } from '@/store/modules/AppModule'
import { moduleSong } from '@/store/modules/SongModule'
import { modulePowerPoint } from '@/store/modules/PowerPointModule'
import { moduleDeroule } from '@/store/modules/DerouleModule'

const store = createStore({
  modules: {
    app: moduleApp,
    song: moduleSong,
    ppt: modulePowerPoint,
    deroule: moduleDeroule,
  }
})

export default store;