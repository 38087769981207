<template>
  <span class="PreloadImages">
    <img v-for="(imagePath, key) in imagePaths" :key="key" :src="$require('@/assets/' + imagePath)" />
  </span>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator'

@Component
export default class PreloadImages extends Vue {

  // Put images paths you would like to preload here :
  imagePaths = [
    'icons/notification/warning.svg',
    'icons/notification/error.svg',
    'icons/notification/info.svg',
    'icons/notification/success.svg',
    'icons/notification/loading.svg',
    'icons/copy.svg',
    'icons/done.svg',
  ]
}
</script>

<style lang="less">

.PreloadImages {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  pointer-events: none;

  img {
    max-height: 0;
    height: 0;
    max-width: 0;
    width: 0;
  }
}

</style>