
import GoogleSheetBoolean from "@/model/enums/GoogleSheetBoolean"
import OrdinaireStep from "@/model/enums/OrdinaireStep"
import Ordinaire from "@/model/interfaces/Ordinaire"
import Song from "@/model/interfaces/Song"
import StepDeroule from "@/model/interfaces/StepDeroule"
import { countErrors } from "@/services/LyricsService"

export const moduleSong = {
  namespaced: true,
  state: () => ({
    songs: null,
    ordinaires: [] as OrdinaireStep[],
    ordinairesSongs: [],
    lastAddedSong: null,
    selectedOrdinaire: null,
    selectedSongs: [] as Song[]
  }),
  mutations: {
    setState: function (state, payload) {
      // 2024-05-18 - Legacy lyrics attribute changed to filename
      state.selectedSongs = payload.state.selectedSongs.map((song: any): Song => {
        return { ...song, song: { ...song.song, filename: (song.song?.filename ?? song.song?.lyrics) }}
      })
      state.lastAddedSong = payload.state.lastAddedSong
      state.selectedOrdinaire = payload.state.selectedOrdinaire
    },
    setSongs: function (state, songs: Partial<Song>[]) {
      state.songs = songs;
    },
    addSong: function (state, song: Song) {
      state.songs.push(song);
      this.commit("song/setLastAddedSong", song)
    },
    setLastAddedSong: function (state, lastAddedSong: Song) {
      state.lastAddedSong = lastAddedSong;
    },
    setSelectedOrdinaire: function (state, selectedOrdinaire: OrdinaireStep) {
      state.selectedOrdinaire = selectedOrdinaire;
    },
    setOrdinaire: function (state, ordinaire: Ordinaire) {
      state.ordinaire = ordinaire;
    },
    setOrdinaires: function (state, ordinaires: Ordinaire[]) {
      state.ordinaires = ordinaires;

      state.ordinairesSongs = []
      ordinaires.forEach(ordinaire => {
        const steps = {
          "kyrie": "Kyrie",
          "gloria": "Gloria",
          "alleluia": "Alléluia",
          "anamnese": "Anamnèse",
          "sanctus": "Sanctus",
          "agnus": "Agnus",
        }
        Object.keys(steps).forEach((key) => {
          if (ordinaire[key] === 'TRUE') {
            state.ordinairesSongs.push({
              name: ordinaire.name + " - " + steps[key],
              ordinaire: ordinaire.name,
              type: key,
            } as Song)
          }
        })
      });
    },
    addSelectedSong: function (state, song: Song) {
      let found = false;
      state.selectedSongs.forEach((s: Song, key: number) => {
        if (s.step === song.step) {
          state.selectedSongs[key] = {...s, ...song, }
          found = true;
        }
      });
      if (!found) state.selectedSongs.push(song);
    },
    setSongNewVerses: function (state, {stepId, rawNewVerses, newVerses}) {
      state.selectedSongs.forEach((s: Song, key: number) => {
        if (s.step === stepId) {
          state.selectedSongs[key].rawNewVerses = rawNewVerses
          state.selectedSongs[key].newVerses = newVerses
          state.selectedSongs[key].countErrors = countErrors(s.rawNewVerses)
        }
      })
    }
  },
  actions: {
  },
  getters: {
    getOrdinaires: (state) => {
      return state.ordinaires.filter((ordinaire: Ordinaire) => ordinaire.hide !== GoogleSheetBoolean.TRUE)
    },
    getSong: (state) => (stepId: StepDeroule['randomKey']): Song | undefined => {
      return state.selectedSongs.find((song: Song) => stepId === song.step)
    },
  }
}
