import * as Papa from "papaparse";
import {getGitlabFile} from "@/services/GitlabService";
import Ordinaire from "@/model/interfaces/Ordinaire";
import Song from "@/model/interfaces/Song";


const sheetId = "199T2yuP-GQ-tjfMmsagWFQgXMJSJGiBWrM_v_lESg3U";
const exportFormat = "csv";
const songsFilenameGoogleSheet = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:${exportFormat}&sheet=`;
const songsFilenameGitlab = `chants.csv`;


async function downloadAndParseFile(filename: string): Promise<any> {
  return new Promise((resolve, reject) => {
    Papa.parse(filename, {
      header: true,
      delimiter: ",",
      download: true,
      skipEmptyLines: true,
      complete(results: any) {
        resolve(results.data);
      },
      error(err: any) {
        reject(err);
      }
    });
  });
}


async function parseFile(fileContent: string): Promise<any> {
  return new Promise((resolve, reject) => {
    Papa.parse(fileContent, {
      header: true,
      delimiter: ",",
      skipEmptyLines: true,
      complete(results: any) {
        resolve(results.data);
      },
      error(err: any) {
        reject(err);
      }
    });
  });
}

export async function readSongsData(): Promise<Partial<Song>[]> {
  const gitlabFile: string = await getGitlabFile(songsFilenameGitlab)
  const result = [
    ...await downloadAndParseFile(songsFilenameGoogleSheet + "chants"),
    ...await parseFile(gitlabFile)
  ].map(data => {
    const { name, author,	lyrics,	alternativeSearch } = data
    return { name, author, filename: lyrics, alternativeSearch }
  })
  return result;
}

export async function readOrdinairesData(): Promise<Ordinaire[]> {
  return await downloadAndParseFile(songsFilenameGoogleSheet + "ordinaires");
}
