
import LyricsEditorType from "@/model/enums/LyricsEditorType";
import store from "@/store"
import { Buffer } from 'buffer'

// Chant repository
const PROJECT_ID = 35860066
const TOKEN = "Z2xwYXQtdEE2cG1UQ25XMzctSENzS3haaTU=";

export const GITLAB_API = `https://gitlab.com/api/v4/projects/${PROJECT_ID}`;
export const MAIN_BRANCH = 'main';
export const HEADERS = {
  headers: new Headers({
    'Content-type': 'application/json',
    'Authorization': `Bearer ${window.atob(TOKEN)}`
  })
}


export async function getGitlabFile(lyricsUrl: string): Promise<string> {
  return new Promise((resolve, reject) => {
    fetch(`${GITLAB_API}/repository/files/${lyricsUrl}?ref=${MAIN_BRANCH}`, {
      method: "GET",
      ...HEADERS
    })
      .then(response => {
        if (!response.ok) reject(err);
        return response.json()
      })
      .then(data => {
        const fileContent = Buffer.from(data.content, 'base64').toString('utf8')
        resolve(fileContent)
      })
      .catch(err => { reject(err) })
  });
}

export async function sendGitlabMR(filePath: string, newContent: string, songName: string, type: LyricsEditorType, createdSongFile = null) {
  const BRANCH_NAME: string = await createBranch(type);
  await createCommit(BRANCH_NAME, filePath, newContent, songName, type, createdSongFile);
  await createMergeRequest(BRANCH_NAME, songName, type);
  return true;
}

async function createBranch(type: LyricsEditorType): Promise<string> {
  const BRANCH_NAME = "lyrics/" +  getAction(type, true) + "-" + getTime()
  return new Promise((resolve, reject) => {
    fetch(`${GITLAB_API}/repository/branches`, {
      method: "POST",
      body: JSON.stringify({
        ref: "main",
        branch: BRANCH_NAME
      }),
      ...HEADERS
    })
      .then(() => BRANCH_NAME)
      .then(data => resolve(data))
      .catch(err => { reject(err) })
  });
}

async function createCommit(branchName: string, filePath: string, newContent: string, songName = "", type: LyricsEditorType, createdSongFile: string|null) {
  let actions = [
    {
      "action": getAction(type, true),
      "file_path": decodeURIComponent(filePath),
      "content": newContent
    }
  ];

  if (type === LyricsEditorType.CREATE) {
    actions = [
      ...actions,
      {
        "action": "update",
        "file_path": "chants.csv",
        "content": createdSongFile
      }
    ]
  }

  return new Promise((resolve, reject) => {
    fetch(`${GITLAB_API}/repository/commits`, {
      method: "POST",
      body: JSON.stringify({
        "branch": branchName,
        "commit_message":  getAction(type) + " lyrics - " + songName,
        "actions": actions
      }),
      ...HEADERS
    })
      .then(() => resolve(true))
      .catch(err => { reject(err) })
  })
}

async function createMergeRequest(branchName: string, songName: string, type: LyricsEditorType) {
  return new Promise((resolve, reject) => {
    fetch(`${GITLAB_API}/merge_requests`, {
      method: "POST",
      body: JSON.stringify({
        source_branch: branchName,
        target_branch: 'main',
        title: getAction(type) + " lyrics - " + songName
      }),
      ...HEADERS
    })
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      store.commit("app/setContributionMRUrl", data.web_url)
      resolve(true)
    })
    .catch(err => { reject(err) })
  })
}

function getAction(type: LyricsEditorType, lowercase = false) {
  const action = (type === LyricsEditorType.EDIT ? "Update" : "Create")
  return lowercase ? action.toLowerCase() : action;
}

function getTime(): string {
  const today = new Date();
  const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const time = today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
  return date + '_' + time;
}