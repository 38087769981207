
import store from "@/store"
import { getGitlabFile } from "@/services/GitlabService";
import Song from "@/model/interfaces/Song";

export const REGEX_VERSE_INDEX = /^([RIPASF]|(\d+))\.$/gm

async function parseFile(lyricsUrl, raw = false) {
  const data = await getGitlabFile(lyricsUrl, false)
  return raw ? data : {
    rawVerses: data,
    verses: parseLyrics(data),
    lyricsUrl
  }
}

export function parseLyrics(rawLyrics) {
  const rawVerses = rawLyrics.split(/\r?\n\n/)
  const verses = [];
  rawVerses.forEach((rawVerse, key) => {
    const match = rawVerse.match(REGEX_VERSE_INDEX);
    verses.push({ id: match != null ? match[0].slice(0, -1) : key, lyrics: rawVerse, active: true })
  });
  return verses;
}

export async function getVerses(song) {
  return await parseFile(getLyricsUrl(song));
}

export async function getCreatedSongsFileContent() {
  return await parseFile("chants.csv", true);
}

function getLyricsUrl(song: Song) {
  let url = "";
  if (typeof song.ordinaire !== "undefined") {
    const ordinaire = store.state.song.ordinaires.find(o => o.name === song.ordinaire)
    url = "ordinaire/" + ordinaire.url + "/" + song.type + ".txt"
  } else {
    url = "chants/" + song.filename;
  }
  return encodeURIComponent(url);
}

function match(regex, lyrics) {
  return (lyrics.match(regex) || []).map(e => e.replace(regex, '$1'));
}

export function countErrors(lyrics: string) {
  return [...errorsMetadata.map(error => {
    return {
      ...error,
      matches: match(error.regex, lyrics)
    }
  }).flatMap(e => e.matches)].length
}

export const errorsMetadata = [
  {
    id: "line-too-big",
    regex: /^((.(?!<.*>)){45})((.+)(?!<.*>))$/gm,
    regexReplace: `$1<mark class="error" type="line-too-big">$3</mark>`,
    highlight: false,
    label: "Ligne trop longue (+ de 45 caractères)",
    reason: "Le texte sautera automatiquement à la ligne, mais la coupure des paroles ne sera pas anticipable, pouvant rendre la lecture du PowerPoint plus difficile",
    resolvements: [
      "Ajouter un saut à la ligne au milieu de la ligne, à un endroit faisant sens (ex: après une virgule)",
    ],
    toggled: false,
  },
  {
    id: "verse-too-big",
    regex: /((^([RIPASF]|\d+)\.$\n)?((^(?!(^([RIPASF]|\d+)\.$\n))(?!\\n).+$\n?){8,}))/gm,
    regexReplace: `<mark class="error" type="verse-too-big">$1</mark>`,
    label: "Paragraphe(s) trop grand (+ de 8 lignes)",
    reason: "Les paroles dépasseront dans le PowerPoint",
    resolvements: [
      "Ajouter un séparateur de slide <code>\\n</code> pour couper le paragraphe sur deux slides",
      "Ou regrouper deux lignes en une seule, en vérifiant que celle-ci ne soit pas trop longue.",
    ],
    toggled: false,
  },
  {
    id: "extra-space-after",
    regex: /([^\S\r\n]+)(\n)/gm,
    regexReplace: `<mark class="error" type="extra-space-after">$1</mark>$2`,
    label: "Espace(s) en trop en fin de ligne",
    reason: "Des espaces en trop peuvent engendrer des soucis d'affichage dans le PowerPoint",
    occurence: false,
    resolvements: [
      "Supprimer l'espace (il est surligné en rouge)",
    ],
    toggled: false,
  },
  {
    id: "extra-space-before",
    regex: /^(\s+).+\n$/gm,
    regexReplace: `<mark class="error" type="extra-space-before">$1</mark>`,
    label: "Espace(s) en trop en début de ligne",
    reason: "Des espaces en trop peuvent engendrer des soucis d'affichage dans le PowerPoint",
    occurence: false,
    resolvements: [
      "Supprimer l'espace (il est surligné en rouge)",
    ],
    toggled: false,
  },
  {
    id: "uppercase",
    regex: /([A-Z\u00C0-\u00DC]+['’\s ,,]*[A-Z\u00C0-\u00DC]+['’\s ,,]*[A-Z\u00C0-\u00DC]+)/gm,
    regexReplace: `<mark class="error" type="uppercase">$1</mark>`,
    label: "Parole(s) en majuscules",
    reason: "Les paroles en majuscules peuvent donner une impression aggressive",
    resolvements: [
      "Les passer en minuscule, en vérifiant que les noms propres sont bien avec une lettre capitale",
    ],
    toggled: false,
  },
  // {
  //   id: "no-capital",
  //   regex: /^([a-z\u00E0-\u00FC])(.*)/gm,
  //   regexReplace: `<mark class="error" type="no-capital">$1</mark>$2`,
  //   label: "Ligne(s) commence par une minuscule",
  //   resolvements: [
  //     "Les passer en majuscule, en vérifiant que les noms propres sont bien avec une lettre capitale",
  //   ],
  //   toggled: false,
  // },
  {
    id: "invalid-verse-index",
    regex: /^(\d+\s*[-/)]).*$/gm,
    regexReplace: `<mark class="error" type="invalid-verse-index">$1</mark>`,
    label: "Index de couplet invalide",
    reason: "Pour que l'outil reconnaisse qu'il s'agit bien d'un couplet, il doit suivre une syntaxe précise",
    resolvements: [
      `Bien suivre la structure suivante : <br><code>1.</code> pour le couplet 1<br><code>2.</code> pour le couplet 2, etc ...<br>`,
      `<b>Puis</b> sauter une ligne`
    ],
    toggled: false,
  },
  {
    id: "invalid-index",
    regex: /^(([RIPASF]|\d+)\..+).*$/gm,
    regexReplace: `<mark class="error" type="invalid-verse-index">$1</mark>`,
    label: "Index de paragraphe invalide",
    reason: "Pour que l'outil reconnaisse qu'il s'agit bien d'un couplet ou d'un refrain, il doit suivre une syntaxe précise",
    resolvements: [
      `Bien suivre la structure suivante :<br><code>R.</code> pour un refrain<br><code>1.</code> pour le couplet 1<br><code>2.</code> pour le couplet 2, etc ...<br><code>I.</code> pour une into<br><code>P.</code> pour un pont/une coda<br><code>A.</code> pour un antienne<br><code>S.</code> pour une stance.`,
      `<b>Puis</b> sauter une ligne`
    ],
    toggled: false,
  },
  {
    id: "chorus-verse-label-detected",
    regex: /^((refrain)|(couplet)|(pont)|(coda)|(bridge)|(antienne)).*$/gim,
    regexReplace: `<mark class="error" type="chorus-verse-label-detected">$1</mark>`,
    label: "Description(s) de paragraphe invalide",
    reason: "Pour que l'outil reconnaisse qu'il s'agit bien d'un couplet ou d'un refrain, il doit suivre une syntaxe précise",
    resolvements: [
      `Bien suivre la structure suivante :<br><code>R.</code> pour un refrain<br><code>1.</code> pour le couplet 1<br><code>2.</code> pour le couplet 2, etc ...<br><code>I.</code> pour une into<br><code>P.</code> pour un pont/une coda<br><code>A.</code> pour un antienne<br><code>S.</code> pour une stance.`,
      `<b>Puis</b> sauter une ligne`
    ],
    toggled: false,
  },
];