<template>
  <div class="Notification" :shown="notification.shown" :status="notification.status" :identifier="notification.id" @click="hideNotification" :hide-loader="notification.hideLoader">
    <img  class="Notification__icon" :src="$require('@/assets/icons/notification/' + notification.status + '.svg')" alt="Notification icon">
    <div class="Notification__messageCtn">
      <span v-san-html="notification.message" class="Notification__message" />
      <span v-if="notification.description" class="Notification__description" >{{ notification.description }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-facing-decorator'
import type NotificationType from '@/model/interfaces/Notification';
import NotificationStatus from '@/model/enums/NotificationStatus';

@Component
export default class Notification extends Vue {

  @Prop({default: 'Default message'})
  notification!: NotificationType

  @Watch('notification')
  notificationWatcher() {
    this.setTimeout()
  }

  mounted() {
    this.$store.commit('app/showNotification', this.notification.id)
    this.setTimeout()
  }

  setTimeout() {
    if (this.notification.status !== NotificationStatus.LOADING) {
      setTimeout(() => {
        this.$store.commit('app/hideNotification', this.notification.id)
      }, 8000)
    }
  }

  hideNotification() {
    this.$store.commit('app/hideNotification', this.notification.id)
  }
}
</script>

<style lang="less">
.Notification {
  @dim-notif-h: 7rem;
  @dim-pad-y: 1rem;
  
  box-sizing: border-box;
  max-width: ~"min(100%, 26rem)";
  // width: fit-content;
  position: relative;
  padding: @dim-pad-y;
  gap: .5rem;
  background: #fff;
  border-radius: @dim-radius;
  transform-origin: bottom right;
  max-height: @dim-notif-h;
  overflow: hidden;
  transition: max-width 0.3s ease-in-out;

  border: solid 1px;
  cursor: pointer;
  pointer-events: all;

  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;

  
  @keyframes show-off {
    20% {
      transform: translateX(-2rem);
    }
    80% {
      transform: translateX(100% + 10rem);
      max-height: @dim-notif-h;
      padding: @dim-pad-y;
    }
    100% {
      transform: translateX(100% + 10rem);
      max-height: 0;
      padding: 0;
    }
  }

  &[status='loading'][shown='false'] {
    animation: show-off .4s ease-in-out 1 forwards;
  }

  &:not([status='loading']):not([hide-loader='true']) {
    animation: show-off .4s 8s ease-in-out 1 forwards;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      transform: scaleX(1);
      transform-origin: left center;
  
      overflow: hidden;
      animation: timeout 8s linear 1 forwards;
    }
  
    @keyframes show-off {
      20% {
        transform: translateX(-2rem);
      }
      80% {
        transform: translateX(100% + 10rem);
        max-height: @dim-notif-h;
        padding: @dim-pad-y;
      }
      100% {
        transform: translateX(100% + 10rem);
        max-height: 0;
        padding: 0;
      }
    }
  }

  @keyframes timeout {
    100% {
      transform: scaleX(0);
    }
  }
  .Notification__messageCtn {

    display: flex;
    flex-flow: column nowrap;
    gap: .25rem;

    .Notification__message {
      font-size: @font-xs - 0.05em;
      line-height: 1rem;
      font-weight: bold;

      code {
        font-family: @font-mono !important;
        padding: .075rem .25rem;
        // background: #f0f0f0;
        color: @color-primary;
        border: solid 1px;
        border-radius: @dim-radius;
        font-weight: 100;
      }
    }
    
    .Notification__description {
      font-size: @font-xxs;
      line-height: 1rem;
    }
  }

  .Notification__icon {
    width: 1.25rem;
  }


  &[shown="true"] {
    transform: translateX(0);
  }
  @color-loading: #666;
  &[status="error"] {
    color: @color-error;
    border-color: lighten(@color-error, 10%);

    &::after {
      background: @color-error;
    }
  }
  &[status="success"] {
    color: @color-success;
    border-color: lighten(@color-success, 10%);

    &::after {
      background: @color-success;
    }
  }
  &[status="info"] {
    color: @color-info;
    border-color: lighten(@color-info, 10%);

    &::after {
      background: @color-info;
    }
  }
  &[status="loading"] {
    color: @color-loading;
    border-color: lighten(@color-loading, 10%);
    
    .Notification__icon {
      width: 2rem;
    }

    &::after {
      background: @color-loading;
    }
  }
}
</style>