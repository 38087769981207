import GitlabCommitActionType from "@/model/enums/GitlabCommitActionType";
import GitlabMetadata from "@/model/interfaces/GitlabMetadata";
import GitlabService from "@/services/AbstractGitlabService";
import store from "@/store"
import { decode } from "@/utils/RandomUtils";
import { normalizeState } from "@/services/JSONBinService";
import StateType from "@/model/enums/StateType";
import NotificationStatus from "@/model/enums/NotificationStatus";

export default class AbstractStateGitlabService {
  // State repository
  private static PROJECT_ID = 51975025
  private static TOKEN = "Z2xwYXQtdEE2cG1UQ25XMzctSENzS3haaTU=";
  
  private static GITLAB_API = `https://gitlab.com/api/v4/projects/${this.PROJECT_ID}`;
  private static HEADERS = {
    headers: new Headers({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${window.atob(this.TOKEN)}`
    })
  }

  static auth: GitlabMetadata = {
    gitlabProjectApiUrl: this.GITLAB_API,
    headers: this.HEADERS
  }

  static getStateTypeLabel(stateType: StateType): string {
    return stateType == StateType.DEROULE ? 'déroulé' : 'style';
  }

  static getStateTypeRepositoryFolder(stateType: StateType): string {
    return stateType == StateType.DEROULE ? 'deroules' : 'styles';
  }

  static getSlugLabel(slug: string, stateType: StateType): string {
    return stateType == StateType.DEROULE ? slug : decode(slug);
  }

  static async createStateGitlabMR(slug: string, update = false, stateType: StateType) {
    const stateLabel = this.getStateTypeLabel(stateType);
    const notificationId = Date.now();
    store.commit('app/addNotification', {
      id: notificationId,
      message: `🚢 Votre ${stateLabel} est en train d'être enregistré`,
      description: `Cela peut prendre plusieurs secondes.`,
      status: NotificationStatus.LOADING,
    })
    
    const isBranchNameAvailable = await this.isBranchNameAvailable(slug, stateType);
    
    if (isBranchNameAvailable || (!isBranchNameAvailable && update)) {
      try {
        await GitlabService.createBranch(slug, this.auth);
        await this.pushState(slug, stateType, update)
        await GitlabService.createMergeRequest(slug, this.auth);
  
        store.commit('app/updateNotification', {
          id: notificationId,
          message: `${update ? '' : 'Bravo ! '}Le ${stateLabel} <code>${this.getSlugLabel(slug, stateType)}</code> a bien été ${update ? 'modifié !' : 'créé ! 🎉'}`,
          description: update ? 'Les personnes disposant de ce lien verront ces nouveaux changements.' : "Copiez et partagez ce lien pour que d'autres puissent l'utiliser",
          status: NotificationStatus.SUCCESS,
        })
      } catch (err: any) {
        store.commit('app/hideNotification', notificationId)
        store.dispatch('app/addErrorNotification', err.message)
      }
    } else {
      store.commit('app/updateNotification', {
        id: notificationId,
        message: `🫤 Zut... il semblerait que ce nom de ${stateLabel} soit déjà utilisé`,
        description: `Essayez d'en choisir un autre...`,
        status: NotificationStatus.ERROR,
      })
    }
  }

  static async pushState(branchName: string, stateType: StateType, update = false) {
    const stateLabel = this.getStateTypeLabel(stateType);
    const action = [{
      action: update ? GitlabCommitActionType.UPDATE : GitlabCommitActionType.CREATE,
      file_path: this.generateFilepath("state.json", branchName, stateType, false),
      content: JSON.stringify(normalizeState(stateType)),
    }]
    await GitlabService.createCommit(branchName, action, this.auth).catch((err: Error) => {
      throw new Error(`Il y a eu une erreur lors de la création du ${stateLabel}`, { cause: err });
    });
  }

  static generateFilepath(filename: string, branchName: string, stateType: StateType, uriEncoded = false): string {
    const stateTypeRepositoryFolder = this.getStateTypeRepositoryFolder(stateType)
    const filePath = stateTypeRepositoryFolder + "/" + branchName + "/" + filename
    return uriEncoded ? encodeURIComponent(filePath) : filePath
  }

  static async getStateFile(styleName: string, branch: string, stateType: StateType) {
    return await GitlabService.getGitlabFile(
      this.generateFilepath("state.json", styleName, stateType, true),
      branch,
      this.auth
    ).catch(err => {throw err})
  }

  static async isBranchNameAvailable(branchName: string, stateType: StateType) {
    const mainData = await this.getStateFile(branchName, GitlabService.MAIN_BRANCH, stateType).catch((err: Error) => err)
    const subBranchData = await this.getStateFile(branchName, branchName, stateType).catch((err: Error) => err)
    return this.is404(mainData) && this.is404(subBranchData);
  }

  static is404(response: any) {
    return response && response.status === 404
  }

  static async getStoredState(branchName: string, stateType: StateType) {
    const stateLabel = this.getStateTypeLabel(stateType);
    const data = await this.getStateFile(branchName, GitlabService.MAIN_BRANCH, stateType).catch(async (err: Error) => {
      if (this.is404(err)) {
        return await this.getStateFile(branchName, branchName, stateType).catch(async (err: Error) => {
          if (this.is404(err)) {
            throw new Error(`Impossible de trouver et d'appliquer le ${stateLabel} spécifié`, { cause: err })
          }
        })
      }
    })
    if (data) {
      const parsedData = JSON.parse(data)
      store.commit('app/setSavedState', {savedState: parsedData, stateType})
      return parsedData
    }

    throw new Error(`Une erreur est survenue lors de la récupération du ${stateLabel}`);
  }

}