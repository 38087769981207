import TempsLiturgique from "@/model/enums/TempsLiturgique";
import LectureReference from "@/model/enums/LectureReference";
import { OrdinaireStep } from "@/model/enums/OrdinaireStep";
import StepDeroule from "@/model/interfaces/StepDeroule";
import { getRandomKey } from "@/utils/RandomUtils";

export const REF_ID = {
  EVANGILE: "evangile"
}

export const moduleDeroule = {
  namespaced: true,
  state: () => ({
    deroule: [
      {
        name: "Entrée",
        ordinaire: false,
        song: true,
        randomKey: getRandomKey()
      },
      {
        name: "Kyrie",
        ordinaire: true,
        ordinaireId: OrdinaireStep.KYRIE,
        song: true,
        randomKey: getRandomKey()
      },
      {
        name: "Gloria",
        ordinaire: true,
        ordinaireId: OrdinaireStep.GLORIA,
        song: true,
        randomKey: getRandomKey(),
      },
      {
        name: "Première Lecture",
        refId: "lecture_1",
        ordinaire: false,
        song: false,
        randomKey: getRandomKey(),
        noBlankPage: true
      },
      {
        name: "Psaume",
        randomKey: getRandomKey(),
        ordinaire: false,
        refId: LectureReference.PSAUME,
        noBlankPage: true
      },
      {
        name: "Deuxième Lecture",
        ordinaire: false,
        song: false,
        randomKey: getRandomKey(),
        refId: LectureReference.LECTURE_2,
      },
      {
        name: "Alléluia",
        ordinaire: true,
        ordinaireId: OrdinaireStep.ALLELUIA,
        song: true,
        randomKey: getRandomKey(),
      },
      {
        name: "Acclamation de l'Évangile",
        ordinaire: false,
        song: true,
        randomKey: getRandomKey(),
      },
      {
        name: "Évangile",
        ordinaire: false,
        song: false,
        randomKey: getRandomKey(),
        refId: LectureReference.EVANGILE,
      },
      {
        name: "Credo",
        ordinaire: false,
        song: false,
        hideInSongSheet: true,
        randomKey: getRandomKey(),
      },
      {
        name: "Prière Universelle",
        ordinaire: false,
        song: true,
        randomKey: getRandomKey(),
      },
      {
        name: "Offertoire",
        ordinaire: false,
        song: true,
        randomKey: getRandomKey(),
      },
      {
        name: "Prière sur les offrandes",
        ordinaire: false,
        song: false,
        hideInSongSheet: true,
        randomKey: getRandomKey(),
      },
      {
        name: "Sanctus",
        ordinaire: true,
        ordinaireId: OrdinaireStep.SANCTUS,
        song: true,
        randomKey: getRandomKey(),
      },
      {
        name: "Anamnèse",
        ordinaire: true,
        ordinaireId: OrdinaireStep.ANAMNESE,
        song: true,
        randomKey: getRandomKey(),
      },
      {
        name: "Notre Père",
        ordinaire: false,
        song: false,
        hideInSongSheet: true,
        randomKey: getRandomKey(),
      },
      {
        name: "Agnus",
        ordinaire: true,
        ordinaireId: OrdinaireStep.AGNUS,
        song: true,
        randomKey: getRandomKey(),
      },
      {
        name: "Communion",
        ordinaire: false,
        song: true,
        randomKey: getRandomKey(),
      },
      {
        name: "Envoi",
        ordinaire: false,
        song: true,
        randomKey: getRandomKey(),
      },
      {
        name: "Annonces",
        ordinaire: false,
        song: false,
        annonce: true,
        annonceContent: "",
        randomKey: getRandomKey(),
      },
    ] as StepDeroule[],
  }),
  mutations: {
    setState: function (state, payload) {
      state.deroule = payload.state.deroule
    },
    setAnnonceContent: function (state, annonceContent) {
      state.annonceContent = annonceContent
      state.deroule.every((step, key) => {
        if (step.annonce === true) {
          state.deroule[key].annonceContent = annonceContent
          return false
        }
        return true;
      });
    },
    addStepToDeroule: function (state, randomKey: StepDeroule['randomKey']) {
      state.deroule.every((step, key) => {
        if (step.randomKey === randomKey) {
          state.deroule.splice(key + 1, 0, {
            name: "",
            randomKey: getRandomKey(),
            song: true,
            custom: true
          })
          return false;
        }
        return true;
      });
    },
    removeStepToDeroule: function (state, payload) {
      state.deroule.every((step, key) => {
        if (step.randomKey === payload.randomKey) {
          state.deroule.splice(key, 1)
          return false;
        }
        return true;
      });
    },
    changeStepName: function (state, payload) {
      state.deroule.every((step, key) => {
        if (step.randomKey === payload.randomKey) {
          state.deroule[key].name = payload.newStepName
          return false;
        }
        return true;
      });
    },
    updateStepImage: function (state, { step, image } ) {
      state.deroule.every((stepDeroule: StepDeroule, key: number) => {
        if (stepDeroule.randomKey === step.randomKey) {
          state.deroule[key].image = image
          return false;
        }
        return true;
      });
    },
  },
  actions: {

  },
  getters: {
    annonceContent(state, getters, rootState): string {
      return state.deroule.find((stepDeroule: StepDeroule) => stepDeroule.annonce === true).annonceContent ?? ""
    },
    deroule(state, getters, rootState) {
      function isActive(step) {
        switch (step) {
          case "Gloria":
            return ![
              TempsLiturgique.AVENT,
              TempsLiturgique.CAREME
            ].includes(rootState.ppt.tempsLiturgique);
          case "Alléluia":
            return rootState.ppt.tempsLiturgique !== TempsLiturgique.CAREME;
          case "Acclamation de l'Évangile":
            return rootState.ppt.tempsLiturgique === TempsLiturgique.CAREME
          default:
            return true;
        }
      }

      return state.deroule
        .map(step => {
          const active = isActive(step.name)
          return { ...step, active}
        })
        .filter(d => (d.active || d.active === undefined))
    }
  }
}