export enum ConfigType {
  DROPDOWN = "dropdown",
  TEXT = "text",
  IMAGE = "image",
  RADIO = "radio",
  COLOR = "color",
  COLORS = "colors",
  SWITCH = "switch",
}

export default ConfigType;