import NotificationStatus from "@/model/enums/NotificationStatus";
import StateType from "@/model/enums/StateType";
import router from "@/router";
import { denormalizeState } from "@/services/JSONBinService";
import StyleGitlabService from "@/services/State/StyleGitlabService";
import store from "@/store";
import { encode } from "@/utils/RandomUtils";
import { RouteLocationRaw } from "vue-router";

export default class StyleService {

  static async loadStyle(style: string): Promise<boolean> {
    if (style !== '') {
      const notificationId = Date.now();
      store.commit('app/addNotification', {
        id: notificationId,
        message: `🪂 Récupération du style...`,
        description: `Cela peut prendre plusieurs secondes.`,
        status: NotificationStatus.LOADING,
      })
      return await StyleGitlabService.getStoredState(encode(style))
      .then(state => {
        denormalizeState(state, StateType.STYLE)
        store.commit('app/updateNotification', {
          id: notificationId,
          message: `Le style <code>${style}</code> a été appliqué !`,
          description: null,
          status: NotificationStatus.SUCCESS,
        })
        return true
      })
      .catch(
        (err: Error) => {
          if (err.cause && err.cause.status === 404) {
            store.commit('app/updateNotification', {
              id: notificationId,
              message: err.message,
              description: "Vérifiez éventuellement que le nom du style ou le lien soit le bon.",
              status: NotificationStatus.ERROR,
            })
          } else {
            store.dispatch('app/addErrorNotification', err.message) 
          }
          return false;
        }
      );
    } else {
      return false;
    }
  }

  static getStyleOnlyUrl(styleSlug: string): string {

    const routeOptions: RouteLocationRaw = {
      name: 'Edit',
      params: {},
      query: { style: styleSlug }
    };
    
    const route = router.resolve(routeOptions);
    return new URL(route.href, window.location.origin).href;
  }

}