import ConfigThematicType from "@/model/enums/ConfigThematicType"
import ConfigItem from "@/model/interfaces/ConfigItem"

export default interface ConfigThematic {
  label: string,
  type: ConfigThematicType,
  priority: number,
  configs: ConfigItem[]
}

export const configThematics: ConfigThematic[] = [
  {
    label: "Textes bibliques",
    type: ConfigThematicType.BIBLE,
    priority: 3,
    configs: [],
  },
  {
    label: "Structure du PowerPoint",
    type: ConfigThematicType.FORMAT,
    priority: 3,
    configs: [],
  },
  {
    label: "Apparence et style",
    type: ConfigThematicType.STYLE,
    priority: 2,
    configs: [],
  },
  {
    label: "Prières",
    type: ConfigThematicType.PRAYER,
    priority: 1,
    configs: [],
  },
  {
    label: "Paroisse",
    type: ConfigThematicType.PARISH,
    priority: 1,
    configs: [],
  }
]