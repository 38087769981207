
import ConfigType from "@/model/enums/ConfigType";
import StateType from "@/model/enums/StateType";
import ConfigItem from "@/model/interfaces/ConfigItem";
import StepDeroule from "@/model/interfaces/StepDeroule";
import store from "@/store"

const JSON_BIN_API_ACCESS_KEY = "$2b$10$F.R/IuMt5Dtct8ANeWVa8.p8QfMmQqatZBt3boyVIKQ1rp4I.be7W";
const JSON_BIN_ENDPOINT = "https://api.jsonbin.io/v3";
const JSON_BIN_PATHS = {
  BIN: "/b"
}
const COLLECTION_ID = "63eb96b9ace6f33a22de4bed"

const HEADERS = new Headers({
  'Content-type': 'application/json',
  // 'X-Master-Key': JSON_BIN_API_KEY,
  'X-Access-Key': JSON_BIN_API_ACCESS_KEY,
  'X-Bin-Private': true,
  // 'X-Bin-Name': <BIN_NAME>,
  'X-Collection-Id': COLLECTION_ID,
});

export async function createBin() {
  const URL = JSON_BIN_ENDPOINT + JSON_BIN_PATHS.BIN
  return new Promise((resolve, reject) => {
    fetch(URL, {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify(normalizeState())
    })
      .then(response => response.json())
      .then(data => resolve(data.metadata.id))
      .catch(() => reject((new Error("Une erreur est survenue lors de l'enregistrement du déroulé."))))
  });
}

export async function updateBin(binId: string) {
  const URL = JSON_BIN_ENDPOINT + JSON_BIN_PATHS.BIN + "/" + binId + "?meta=false"
  return new Promise((resolve, reject) => {
    fetch(URL, {
      method: 'PUT',
      headers: HEADERS,
      body: JSON.stringify(normalizeState())
    })
      .then(response => response.json())
      .then(data => resolve(data.metadata.id))
      .catch(() => reject((new Error("Une erreur est survenue lors de l'enregistrement du déroulé."))))
  });
}

export async function readBin(binId: string) {
  const URL = JSON_BIN_ENDPOINT + JSON_BIN_PATHS.BIN + "/" + binId + "?meta=false"
  return new Promise((resolve, reject) => {
    fetch(URL, {
      method: 'GET',
      headers: HEADERS,
    })
      .then(response => {
        if (response.ok) {  
          return response.json();
        }
        return null
      })
      .then(data => resolve(data))
      .catch(() => reject((new Error("Une erreur est survenue lors de la lecture du déroulé."))))
  });
}

export function normalizeState(stateType: StateType = StateType.DEROULE, isSaving = true, removeImages = true) {
  const state: any = {}

  if (stateType === StateType.DEROULE) {
    // Remove heavy unused data
    const stateSong = JSON.parse(JSON.stringify(store.state.song));
    delete stateSong.songs
    delete stateSong.ordinaires
    delete stateSong.ordinairesSongs
    
    state['song'] = stateSong

    // Remove unsplash images data from step deroule 
    const stateDeroule = JSON.parse(JSON.stringify(store.state.deroule));
    stateDeroule.deroule.forEach((stepDeroule: StepDeroule, key: number) => {
      if (stepDeroule.image !== undefined && removeImages) {
        stateDeroule.deroule[key].image.unsplashImageData = null 
      }
    });

    state['deroule'] = stateDeroule
  }

  // Remove images data from config images
  const statePpt = JSON.parse(JSON.stringify(store.state.ppt));
  if (stateType === StateType.DEROULE) {
    statePpt.config.ppt.forEach((config: ConfigItem, key: number) => {
      if (config['type'] === ConfigType.IMAGE && statePpt.config.ppt[key].data !== null && removeImages) {
        statePpt.config.ppt[key].data.unsplashImageData = null
      }
    });
  }

  state['ppt'] = statePpt

  if (isSaving) {
    store.commit('app/setSavedState', {savedState: state, stateType})
  }

  return state
}

export function denormalizeState(state, stateType: StateType) {
  store.commit('app/setSavedState', {savedState: state, stateType})
  if (state.ppt) {
    store.commit('ppt/setState', { state: state.ppt, stateType })
  }
  if (state.deroule) {
    store.commit('deroule/setState', { state: state.deroule, stateType })
  }
  if (state.song) {
    store.commit('song/setState', { state: state.song, stateType })
  }
}