import LyricsEditorType from "@/model/enums/LyricsEditorType";
import StateType from "@/model/enums/StateType";
import SupportMode from "@/model/enums/SupportMode";
import StepDeroule from "@/model/interfaces/StepDeroule";
import { debounce, deepEqual, slugify } from "@/utils/RandomUtils";
import Notification from "@/model/interfaces/Notification";
import NotificationStatus from "@/model/enums/NotificationStatus";
import StyleLocalStorage from "@/model/interfaces/StyleLocalStorage";
import { normalizeState } from "@/services/JSONBinService";
import DerouleLocalStorage from "@/model/interfaces/DerouleLocalStorage";
import DerouleService from "@/services/State/DerouleService";

export const moduleApp = {
  namespaced: true,
  state: () => ({
    mode: null as SupportMode | null, // "sheet|ppt"
    stateType: StateType.STYLE,
    saveUploadedImage: false,
    styleName: "",
    styleSavedState: null,
    derouleSavedState: null,
    derouleKey: null as string | null,
    lastSaveTimer: null,
    contributionPopupShown: false,
    songSheetPopupShown: true,
    feedbackIframeShown: false,
    psalmConfigurationShown: false,
    advancedConfigShown: false,
    derouleSharingPopupShown: false,
    styleSharingPopupShown: false,
    whatsNewPopupShown: true,
    lastVisitDate: null,
    AELFMessePickerShown: false,
    deroulePickerDate: null,
    isHistoryShown: false,
    tipsPopupShown: false,
    pptIsBuilding: false,
    configShown: false,
    savedConfigInfoShown: true,
    savedSessionDate: null,

    activeVerseSong: null,
    activeImageSong: null,

    activeLyricsEditorStep: null as StepDeroule['randomKey'] | null,
    activeLyricsEditorType: null as LyricsEditorType | null,
    contributionMRUrl: "",
    notifications: [] as Notification[],
    usedStyles: [] as StyleLocalStorage[],
    usedDeroules: [] as DerouleLocalStorage[]
  }),
  mutations: {
    initialiseStore(state) {
      const storedStyles = localStorage.getItem("styles")
      if (storedStyles != null) {
        const usedStyles = JSON.parse(storedStyles);
        this.commit('app/setUsedStyles', usedStyles)
      }
      const storedDeroules = localStorage.getItem("deroules")
      if (storedDeroules != null) {
        const usedDeroules = JSON.parse(storedDeroules);
        this.commit('app/setUsedDeroules', usedDeroules)
      }
      const storedLastVisitDate = localStorage.getItem("lastVisitDate")
      if (storedLastVisitDate != null) {
        const lastVisitDate = new Date(storedLastVisitDate);
        this.commit('app/setLastVisitDate', lastVisitDate)
      }
    },
    setUsedStyles(state, usedStyles) {
      state.usedStyles = usedStyles;
    },
    setUsedDeroules(state, usedDeroules) {
      state.usedDeroules = usedDeroules;
    },
    setLastSaveTimer(state, lastSaveTimer: Date) {
      state.lastSaveTimer = lastSaveTimer;
    },
    setMode: function (state, mode: SupportMode) {
      state.mode = mode;
      this.dispatch("ppt/saveConfigInLocalStorage");
    },
    setStateType: function (state, stateType: StateType) {
      state.stateType = stateType;
    },
    setSavedState: function (state, payload: { savedState: [], stateType: StateType }) {
      if (payload.stateType === StateType.STYLE) {
        state.styleSavedState = JSON.parse(JSON.stringify(payload.savedState))
      } else if (payload.stateType === StateType.DEROULE) {
        state.derouleSavedState = JSON.parse(JSON.stringify(payload.savedState))
      }
    },
    setSaveUploadedImage: function (state, saveUploadedImage: boolean) {
      state.saveUploadedImage = saveUploadedImage;
    },
    setStyleName: function (state, styleName: string) {
      state.styleName = styleName;
    },
    setDerouleKey: function (state, derouleKey: string) {
      state.derouleKey = derouleKey;
      this.commit('app/setIsHistoryShown', derouleKey != null)
      this.dispatch("app/saveDerouleInLocalStorage", { key: derouleKey });
    },
    setPptIsBuilding: function (state, pptIsBuilding: boolean) {
      state.pptIsBuilding = pptIsBuilding;
    },
    setSavedConfigInfoShown: function (state, savedConfigInfoShown: boolean) {
      state.savedConfigInfoShown = savedConfigInfoShown;
    },
    setWhatsNewPopupShown: function (state, whatsNewPopupShown: boolean) {
      state.whatsNewPopupShown = whatsNewPopupShown;
    },
    setLastVisitDate: function (state, lastVisitDate: Date) {
      state.lastVisitDate = lastVisitDate;
    },
    setFeedbackIframeShown: function (state, feedbackIframeShown: boolean) {
      state.feedbackIframeShown = feedbackIframeShown;
    },
    setContributionPopupShown: function (state, contributionPopupShown: boolean) {
      state.contributionPopupShown = contributionPopupShown;
    },
    setSongSheetPopupShown: function (state, songSheetPopupShown: boolean) {
      state.songSheetPopupShown = songSheetPopupShown;
    },
    setAdvancedConfigShown: function (state, isShown: boolean) {
      state.advancedConfigShown = isShown;
    },
    setIsHistoryShown: function (state, isHistoryShown: boolean) {
      state.isHistoryShown = isHistoryShown;
    },
    toggleAdvancedConfigShown: function (state) {
      state.advancedConfigShown = !state.advancedConfigShown;
    },
    setDerouleSharingPopupShown: function (state, derouleSharingPopupShown: boolean) {
      state.derouleSharingPopupShown = derouleSharingPopupShown;
    },
    setStyleSharingPopupShown: function (state, styleSharingPopupShown: boolean) {
      state.styleSharingPopupShown = styleSharingPopupShown;
    },
    setTipsPopupShown: function (state, tipsPopupShown: boolean) {
      state.tipsPopupShown = tipsPopupShown;
    },
    setDeroulePickerDate: function (state, deroulePickerDate: Date|null) {
      state.deroulePickerDate = deroulePickerDate;
    },
    setAELFMessePickerShown: function (state, AELFMessePickerShown: boolean) {
      state.AELFMessePickerShown = AELFMessePickerShown;
      if (AELFMessePickerShown === false && this.state.ppt.AELFActiveMesseKey === null) {
        this.commit('ppt/setAELFActiveMesseKey', 0)
      }
      this.dispatch("ppt/updateAELFValues");
    },
    setPsalmConfigurationShown: function (state, psalmConfigurationShown: boolean) {
      state.psalmConfigurationShown = psalmConfigurationShown;
    },
    setConfigShown: function (state, configShown: boolean) {
      state.configShown = configShown;
    },
    setActiveLyricsEditorType: function (state, type: LyricsEditorType) {
      state.activeLyricsEditorType = type;
    },
    setActiveLyricsEditorStep: function (state, stepId: StepDeroule['randomKey']|null) {
      state.activeLyricsEditorStep = stepId;
    },
    setContributionMRUrl: function (state, contributionMRUrl) {
      state.contributionMRUrl = contributionMRUrl;
    },
    setActiveVerseSong: function (state, activeVerseSong) {
      state.activeVerseSong = activeVerseSong;
    },
    setActiveImageSong: function (state, activeImageSong) {
      state.activeImageSong = activeImageSong;
    },
    updateNotification(state, notification: Notification) {
      state.notifications.forEach((n: Notification, key: number) => {
        if (n.id === notification.id) {
          state.notifications[key] = {...n, ...notification }
        }
      });
    },
    addNotification(state, notification: Notification) {
      if (notification.id === undefined) {
        do {
          notification.id = Date.now()
        } while (state.notifications.find((n: Notification) => n.id === notification.id));
      }
      notification.shown = false
      state.notifications.push(notification)
    },
    showNotification(state, notificationId: Notification['id']) {
      state.notifications.forEach((notification: Notification, key: number) => {
        if (notification.id === notificationId) {
          state.notifications[key].shown = true
        }
      });
    },
    hideNotification(state, notificationId: Notification['id']) {
      state.notifications.forEach((notification: Notification, key: number) => {
        if (notification.id === notificationId) {
          state.notifications[key].shown = false
          setTimeout(() => {
            state.notifications.splice(key, 1);
          }, 1000)
        }
      });
    },
  },
  actions: {
    saveDerouleInLocalStorage({ commit, state, rootState, rootGetters }, payload: DerouleLocalStorage) {
      const storedDeroules = localStorage.getItem('deroules')
      let deroules: any[] = [];
      let derouleExists = false;
      const date = new Date(rootState.ppt.config.date) ?? DerouleService.parseDateFromDerouleKey(state.derouleKey)
      const AELFActiveMesse = rootGetters["ppt/getActiveAELFMesse"]?.nom

      if (storedDeroules != null) {
        deroules = JSON.parse(storedDeroules);
        deroules.forEach((deroule: DerouleLocalStorage, key: number) => {
          if (deroule.key == state.derouleKey) {
            derouleExists = true
            deroules[key].date = date
            deroules[key].AELFActiveMesse = AELFActiveMesse
          }
        });
      }
      
      if (!derouleExists) {
        const deroule: DerouleLocalStorage = {
          key: state.derouleKey,
          date,
          AELFActiveMesse
        };
        deroules.push(deroule)
      }

      commit('setUsedDeroules', deroules)
      deroules = deroules.filter((deroule: DerouleLocalStorage) => deroule.key != "")
      localStorage.setItem('deroules', JSON.stringify(deroules));
    },
    addErrorNotification ({ commit }, errorMessage: string) {
      if (window.navigator.onLine) {
        commit('addNotification', {
          message: errorMessage,
          description: "Un rapport d'erreur a été envoyé. Si c'est un bug, il sera corrigé rapidemment. Pardon pour le dérangement 🙏",
          status: NotificationStatus.ERROR,
        })
      } else {
        commit('addNotification', {
          message: "🏝️ Il semblerait que vous ne soyez plus connecté à internet.",
          description: "Vérifiez votre connexion puis réessayez.",
          status: NotificationStatus.ERROR,
        })
      }
    }
  },
  getters: {
    isPptMode: (state) => {
      return state.mode === SupportMode.PPT
    },
    isSheetMode: (state) => {
      return state.mode === SupportMode.SHEET
    },
    styleSlug: (state) => {
      return slugify(state.styleName)
    },
    activeUsedStyle: (state, getters, rootState): StyleLocalStorage => {
      return state.usedStyles.find((style: StyleLocalStorage) => style.name === rootState.ppt.config.style)
    },
    hasSavedStateChanged: (state) => (stateType: StateType) => {
      const savedState = stateType === StateType.DEROULE ? state.derouleSavedState : state.styleSavedState;
      if (savedState == null) {
        return false
      }
      const actualState = normalizeState(stateType, false, false)
      const changesFound = !deepEqual(JSON.parse(JSON.stringify(savedState)), actualState, ['unsplashImageData', 'style']);
      return changesFound
    }
  }
}
