import GitlabCommitAction from "@/model/interfaces/GitlabCommitAction";
import GitlabMetadata from "@/model/interfaces/GitlabMetadata";
import { Buffer } from 'buffer'

export default class AbstractGitlabService {
  
  static MAIN_BRANCH = 'main';

  static async getGitlabFile(
    pathUrl: string,
    branch: string = this.MAIN_BRANCH,
    auth: GitlabMetadata,
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      fetch(`${auth.gitlabProjectApiUrl}/repository/files/${pathUrl}?ref=${branch}`, {
        method: "GET",
        ...auth.headers
      })
        .then(response => {
          if (!response.ok) throw response
          return response.json()
        })
        .then(data => {
          const fileContent = Buffer.from(data.content, 'base64').toString('utf8')
          resolve(fileContent)
        })
        .catch(err => {
          reject(err)
        })
    });
  }

  static async createBranch(branchName: string, auth: GitlabMetadata): Promise<string> {
    return new Promise((resolve, reject) => {
      fetch(`${auth.gitlabProjectApiUrl}/repository/branches`, {
        method: "POST",
        body: JSON.stringify({
          ref: "main",
          branch: branchName
        }),
        ...auth.headers
      })
        .then(() => branchName)
        .then(data => resolve(data))
        .catch(err => { reject(err) })
    });
  }

  static async getListOfFolders(folderPath: string, branchName: string = this.MAIN_BRANCH, auth: GitlabMetadata): Promise<string[]> {
    return new Promise((resolve, reject) => {
      fetch(`${auth.gitlabProjectApiUrl}/repository/tree?ref=${branchName}&path=${folderPath}&per_page=100`, {
        method: "GET",
        ...auth.headers
      })
        .then(response => {
          if (!response.ok) reject(err);
          return response.json()
        })
        .then(data => resolve(data.filter(file => file.type === 'tree').map(f => f.name)))
        .catch(err => { reject(err) })
    });
  }

  static async checkBranchExists(branchName: string, auth: GitlabMetadata): Promise<boolean> {
    return new Promise((resolve, reject) => {
      fetch(`${auth.gitlabProjectApiUrl}/repository/branches/${branchName}`, {
        method: "GET",
        ...auth.headers
      })
        .then(response => {
          if (response.ok) {
            return true
          } else if(response.status === 404) {
            return false
          } else {
            return Promise.reject('some other error: ' + response.status)
          }
        })
        .then(data => resolve(data))
        .catch(err => { reject(err) })
    });
  }

  static async createCommit(branchName: string, actions: GitlabCommitAction[], auth: GitlabMetadata) {
    // let actions = [
    //   {
    //     "action": getAction(type, true),
    //     "file_path": decodeURIComponent(filePath),
    //     "content": newContent
    //   }
    // ];
  
    // if (type === LyricsEditorType.CREATE) {
    //   actions = [
    //     ...actions,
    //     {
    //       "action": "update",
    //       "file_path": "chants.csv",
    //       "content": createdSongFile
    //     }
    //   ]
    // }
  
    return new Promise((resolve, reject) => {
      fetch(`${auth.gitlabProjectApiUrl}/repository/commits`, {
        method: "POST",
        body: JSON.stringify({
          "branch": branchName,
          "commit_message": "lorem",
          "actions": actions
        }),
        ...auth.headers
      })
        .then(() => resolve(true))
        .catch(err => reject(err))
    })
  }
    
  static async createMergeRequest(branchName: string, auth: GitlabMetadata) {
    return new Promise((resolve, reject) => {
      fetch(`${auth.gitlabProjectApiUrl}/merge_requests`, {
        method: "POST",
        body: JSON.stringify({
          source_branch: branchName,
          target_branch: this.MAIN_BRANCH,
          title: "Create style - " + branchName
        }),
        ...auth.headers
      })
      .then((response) => {
        return response.json()
      })
      .then(() => {
        resolve(true)
      })
      .catch(err => { reject(err) })
    })
  }

}
// Chant repository
// const PROJECT_ID = 35860066
// const TOKEN = "Z2xwYXQtdEE2cG1UQ25XMzctSENzS3haaTU=";

// // Style repository
// // const PROJECT_ID = 51975025
// // const TOKEN = "Z2xwYXQtdEE2cG1UQ25XMzctSENzS3haaTU=";

// export const GITLAB_API = `https://gitlab.com/api/v4/projects/${PROJECT_ID}`;
// export const HEADERS = {
//   headers: new Headers({
//     'Content-type': 'application/json',
//     'Authorization': `Bearer ${window.atob(TOKEN)}`
//   })
// }



// export async function sendGitlabMR(filePath: string, newContent: string, songName: string, type: LyricsEditorType, createdSongFile = null) {
//   const BRANCH_NAME: string = await createBranch(type);
//   await createCommit(BRANCH_NAME, filePath, newContent, songName, type, createdSongFile);
//   await createMergeRequest(BRANCH_NAME, songName, type);
//   return true;
// }



// async function createCommit(branchName: string, filePath: string, newContent: string, songName = "", type: LyricsEditorType, createdSongFile: string|null) {
//   let actions = [
//     {
//       "action": getAction(type, true),
//       "file_path": decodeURIComponent(filePath),
//       "content": newContent
//     }
//   ];

//   if (type === LyricsEditorType.CREATE) {
//     actions = [
//       ...actions,
//       {
//         "action": "update",
//         "file_path": "chants.csv",
//         "content": createdSongFile
//       }
//     ]
//   }

//   return new Promise((resolve, reject) => {
//     fetch(`${GITLAB_API}/repository/commits`, {
//       method: "POST",
//       body: JSON.stringify({
//         "branch": branchName,
//         "commit_message":  getAction(type) + " lyrics - " + songName,
//         "actions": actions
//       }),
//       ...HEADERS
//     })
//       .then(() => resolve(true))
//       .catch(err => { reject(err) })
//   })
// }

// async function createMergeRequest(branchName: string, songName: string, type: LyricsEditorType) {
//   return new Promise((resolve, reject) => {
//     fetch(`${GITLAB_API}/merge_requests`, {
//       method: "POST",
//       body: JSON.stringify({
//         source_branch: branchName,
//         target_branch: 'main',
//         title: getAction(type) + " lyrics - " + songName
//       }),
//       ...HEADERS
//     })
//     .then((response) => {
//       return response.json()
//     })
//     .then((data) => {
//       store.commit("app/setContributionMRUrl", data.web_url)
//       resolve(true)
//     })
//     .catch(err => { reject(err) })
//   })
// }

// function getAction(type: LyricsEditorType, lowercase = false) {
//   const action = (type === LyricsEditorType.EDIT ? "Update" : "Create")
//   return lowercase ? action.toLowerCase() : action;
// }

// function getTime(): string {
//   const today = new Date();
//   const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
//   const time = today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
//   return date + '_' + time;
// }